@import url('https://fonts.googleapis.com/css2?family=Mountains+of+Christmas&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
body {
  background-color: #333;
  /* font-family: 'Mountains of Christmas', cursive; */
  font-family: 'Great Vibes', cursive;
  background-image: url(./assets/images/bg-new.png);
}

#root {
  width: 100vw;
  min-height: 100vh;
}

.App {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-button {
  position: fixed;
  right: 1em;
  bottom: 1em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1em;
  background-color: #444;
  color: #ddd;
  padding: 0.3em 0.9em;
  cursor: pointer;
}
