.card {
  font-size: 2em;
  position: absolute;
  color: whitesmoke;
  display: inline-block;
  width: calc(100% / 3 - 2%);
  height: calc(100% / 2 - 2%);
  transform-style: preserve-3d;
  user-select: none;
}
.card--active {
  -webkit-filter: drop-shadow(0 0 0.5em #c1a368aa);
  filter: drop-shadow(0 0 0.5em #c1a368aa);
}

.card--revealed .card--img {
  transition: 0.5s;
  filter: saturate(0.1);
}

.grid--with-active .card.card--revealed > img.card--img {
  transition: filter 0.5s;
  filter: saturate(0.1) contrast(0.5);
}

.card--img {
  font-size: 3em;
  position: absolute;
  inset: 0;
  color: #888;
  backface-visibility: hidden;
  margin: auto;
  max-height: 100%;
}
