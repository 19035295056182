.door {
  position: absolute;
  display: inline-block;
  width: calc(100% / 3 - 2%);
  height: calc(100% / 2 - 2%);
  cursor: pointer;
  z-index: 3;
  transform-origin: left;
  user-select: none;
  /* border: 1px solid #c1a368; */
}

.door::after {
  content: '';
  display: block;
  position: absolute;
  inset: 0;
  box-shadow: inset 0 0 5em #c1a36833;
  transition: 1s;
}
.door:hover::after {
  box-shadow: inset 0 0 4em #c1a368ff;
}

img.door--img {
  width: 100%;
  height: 100%;
}
.door--numbering {
  font-size: 5em;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.grid--with-active > .door > img.door--img {
  transition: 0.5s;
  filter: contrast(0.5);
}
.door--numbering .lock {
  font-size: 0.5em;
}
